<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              <feather-icon
                icon="ArrowLeftIcon"
                size="24"
                class="mr-2"
                @click="goBack"
              />Expense
            </h2>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <span aria-current="location"> Add </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <validation-observer ref="simpleRules">
      <b-form method="post" @submit="expenseAdd">
        <b-card>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Production Type<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name=" Production Type"
                    rules="required"
                  >
                    <v-select
                      v-model="FormData.production_type_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="productionTypes"
                      label="name"
                      clearable:false
                      :reduce="(e) => e.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Select Flock<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <v-select
                      v-model="FormData.flock_id"
                      :options="flockList"
                      label="flock_id"
                      clearable:false
                      :reduce="(e) => e.flock_id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Date <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="date"
                  rules="required"
                >
                  <b-form-datepicker
                    name="date"
                    v-model="FormData.date"
                    menu-class="w-100"
                    calendar-width="100%"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Rent<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Rent"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="FormData.rent"
                      placeholder="Rent"
                      type="text"
                      name="rent"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Fuel/Electricity <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Fuel/Electricity"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="FormData.fuel"
                      placeholder="Fuel"
                      type="text"
                      name="fuel"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Labour Salary<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Labour Salary"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="FormData.labour_sal"
                      placeholder="Labour Salary"
                      type="text"
                      name="labour_sal"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Litter Cost<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Litter Cost"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="FormData.litter_cost"
                      placeholder="Litter Cost"
                      type="text"
                      name="litter cost"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  POL <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="POL"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="FormData.pol"
                      placeholder="POL"
                      type="text"
                      name="pol"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Other<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Other"
                  rules="required"
                >
                  <div class="form-label-group">
                    <b-form-input
                      v-model="FormData.other"
                      placeholder="Other"
                      type="text"
                      name="other"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- submit and reset -->
          <b-col>
            <b-button
              type="submit"
              variant="primary"
              value="Submit"
              class="mr-1 mb-3"
            >
              Submit
            </b-button>
          </b-col>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import * as Vue from "vue";

import { required, email } from "@validations";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import axiosIns from "@/libs/axios";

export default {
  components: {
    vSelect,
  },

  data() {
    return {
      selectedRoute: null,
      selected: null,
      FormData: {
        production_type_id: null,
        rent: null,
        fuel: null,
        electricity: null,
        labour_sal: null,
        litter_cost: null,
        flock_id: null,
        pol: null,
        other: null,
      },
      productionTypes: [],
      flockList: [],
      required,
      email,
    };
  },
  created: function () {
    this.getFlockList();
    this.getProductionType();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getProductionType() {
      axiosIns
        .get(`web/production-types`)
        .then((response) => {
          this.productionTypes = response.data.production_type.data;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    getFlockList() {
      const farm_id = this.$route.params.farmId;
      axiosIns
        .get(`web/farm/${farm_id}/flock-list`)
        .then((response) => {
          this.flockList = response.data.flock_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    expenseAdd(e) {
      e.preventDefault();
      const farm_id = this.$route.params.farmId;
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          var data = new FormData();
          data.append("rent", this.FormData.rent);
          data.append("fuel", this.FormData.fuel);
          data.append("date", this.FormData.date);
          data.append("production_type_id", this.FormData.production_type_id);
          data.append("labour_sal", this.FormData.labour_sal);
          data.append("litter_cost", this.FormData.litter_cost);
          data.append("pol", this.FormData.pol);
          data.append("other", this.FormData.other);
          const flock_id = this.FormData.flock_id;
          axiosIns
            .post(`web/farm/${farm_id}/flock/${flock_id}/expenses`, data)
            .then((res) => {
              res;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `data Successfully Added `,
                },
              });
              setTimeout(
                this.$router.push({
                  name: `apps-expense-list`,
                }),
                3000
              );
            })
            .catch((error) => {
              error.response;
              const data = error.response.data.message;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: data,
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
