var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row content-header"},[_c('div',{staticClass:"content-header-left mb-2 col-md-9 col-12"},[_c('div',{staticClass:"row breadcrumbs-top"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0"},[_c('feather-icon',{staticClass:"mr-2",attrs:{"icon":"ArrowLeftIcon","size":"24"},on:{"click":_vm.goBack}}),_vm._v("Expense ")],1),_vm._m(0)])])])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{attrs:{"method":"post"},on:{"submit":_vm.expenseAdd}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Production Type"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":" Production Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.productionTypes,"label":"name","clearable:false":"","reduce":function (e) { return e.id; }},model:{value:(_vm.FormData.production_type_id),callback:function ($$v) {_vm.$set(_vm.FormData, "production_type_id", $$v)},expression:"FormData.production_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Flock"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.flockList,"label":"flock_id","clearable:false":"","reduce":function (e) { return e.flock_id; }},model:{value:(_vm.FormData.flock_id),callback:function ($$v) {_vm.$set(_vm.FormData, "flock_id", $$v)},expression:"FormData.flock_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"name":"date","menu-class":"w-100","calendar-width":"100%"},model:{value:(_vm.FormData.date),callback:function ($$v) {_vm.$set(_vm.FormData, "date", $$v)},expression:"FormData.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Rent"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Rent","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{attrs:{"placeholder":"Rent","type":"text","name":"rent"},model:{value:(_vm.FormData.rent),callback:function ($$v) {_vm.$set(_vm.FormData, "rent", $$v)},expression:"FormData.rent"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Fuel/Electricity "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Fuel/Electricity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{attrs:{"placeholder":"Fuel","type":"text","name":"fuel"},model:{value:(_vm.FormData.fuel),callback:function ($$v) {_vm.$set(_vm.FormData, "fuel", $$v)},expression:"FormData.fuel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Labour Salary"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Labour Salary","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{attrs:{"placeholder":"Labour Salary","type":"text","name":"labour_sal"},model:{value:(_vm.FormData.labour_sal),callback:function ($$v) {_vm.$set(_vm.FormData, "labour_sal", $$v)},expression:"FormData.labour_sal"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Litter Cost"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Litter Cost","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{attrs:{"placeholder":"Litter Cost","type":"text","name":"litter cost"},model:{value:(_vm.FormData.litter_cost),callback:function ($$v) {_vm.$set(_vm.FormData, "litter_cost", $$v)},expression:"FormData.litter_cost"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" POL "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"POL","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{attrs:{"placeholder":"POL","type":"text","name":"pol"},model:{value:(_vm.FormData.pol),callback:function ($$v) {_vm.$set(_vm.FormData, "pol", $$v)},expression:"FormData.pol"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Other"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Other","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{attrs:{"placeholder":"Other","type":"text","name":"other"},model:{value:(_vm.FormData.other),callback:function ($$v) {_vm.$set(_vm.FormData, "other", $$v)},expression:"FormData.other"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',[_c('b-button',{staticClass:"mr-1 mb-3",attrs:{"type":"submit","variant":"primary","value":"Submit"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumb-wrapper"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item active"},[_c('span',{attrs:{"aria-current":"location"}},[_vm._v(" Add ")])])])])}]

export { render, staticRenderFns }